import { FC, useCallback } from 'react';

import Namespace from '@/constants/namespace';
import PageError from '@/modules/general/PageError';
import { useTranslation } from '@/services/I18nNext';
import Router from '@/utils/nextRouter';

const PageErrorNotFound: FC = () => {
  const { t } = useTranslation([Namespace.ODP_GENERAL]);
  const handleClick = useCallback(() => Router.back(), []);
  return (
    <PageError
      errorCodeText={t('general.text_404_error')}
      title={t('general.title_page_not_found')}
      description={t('general.text_page_not_found_description')}
      buttonText={t('general.action_bring_me_back')}
      onButtonClick={handleClick}
    />
  );
};

export default PageErrorNotFound;
