import { GetStaticProps, NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import Namespace from '@/constants/namespace';
import PageErrorNotFound from '@/modules/general/PageErrorNotFound';

const IXT404: NextPage = () => <PageErrorNotFound />;

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(locale ? await serverSideTranslations(locale, [Namespace.ODP_GENERAL]) : null),
  },
});

export default IXT404;
